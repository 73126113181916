@import url("../../variables.css");

.sharedbutton{
    width: 10rem;
    height: auto;
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
}

.sharedoutlinebtn{
    background-color: transparent;
    border: 2px solid var(--color-text-white);
    color: var(--color-text-white);
}
.sharedoutlinebtn:hover{
    color: var(--color-header-text);
    background-color: var(--color-text-white);
    border:2px solid var(--color-bg-button );
}

.sharedfilledbtn{
    background: url("../../../assets/common/btn-bg.png");
    background-size: cover;
    border: 2px solid var(--color-bg-button);
    color: var(--color-text-white);
}
.sharedfilledbtn:hover{
    color: var(--color-header-text);    
    border:2px solid var(--color-header-text);
    background: var(--color-text-white);
}

/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
   
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .sharedbutton{
        width: 6rem;
        height: auto;
        padding: 5px;
        border-radius: 10px;
        font-size: 0.6rem;
    }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    .sharedbutton{
        width: 6rem;
        height: auto;
        padding: 5px;
        border-radius: 10px;
        font-size: 0.7rem;
    }
}