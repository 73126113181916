
@import url("../../variables.css");

/* 
.splash-maincontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; 
    color: var(--color-header-text);
    font-weight: bold;
}

.splash-container {
    text-align: center;
}

.splash-iframe {
    width: 300px;
    height: 300px;
    border: none;
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
    transform: scale(1);
    opacity: 1;
}

.splash-iframe.animate {
    transform: scale(1.5); 
    opacity: 0;
}

.raju-logo {
    width: 200px;
    height: auto;
    transition: opacity 1.5s ease-in-out;
    opacity: 0; 

.raju-logo.show {
    opacity: 1;
} */


.splash-maincontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* Adjust as necessary */
    color: var(--color-header-text);
    font-weight: bold;
}

.splash-container {
    text-align: center;
}

/* Default logo style */
.raju-logo {
    width: 200px;
    height: auto;
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
    transform: rotate(0deg);
    opacity: 1; /* Initially visible */
}

/* Add rotation and fade-out */
.raju-logo.rotate-fade {
    animation: rotate-logo 3s ease-in-out, fade-out 1.5s ease-in-out forwards;
    transform: rotate(0deg);
}

/* Keyframes for rotating the logo */
@keyframes rotate-logo {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg); /* Rotate to the right */
    }
    50% {
        transform: rotate(-15deg); /* Rotate to the left */
    }
    75% {
        transform: rotate(25deg); /* Rotate to the right again */
    }
    100% {
        transform: rotate(0deg); /* Back to the original position */
    }
}

/* Keyframes for fading out the logo */
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    25%{
        opacity: 0.8;
    }
    50%{
        opacity: 0.5;
    }
    75%{
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
