@import url("../variables.css");

.contact-carousel-container {
    width: 100%;
    margin: 0 auto;
}

.contact-slide {
    text-align: center;
    padding: 10px;
    transition: transform 0.3s ease;
}
.contact-slide:hover{
    transform: scale(1.1); 
    cursor: pointer;
}

.contact-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    /* max-height: 300px;  */
}

/* contact-form-container */
.contact-form-container{
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.contact-form-container .form-headings-text{
    color: var(--color-header-text);
    text-align: center;
    font-weight: bold;
}
.contact-form-container .form-headings-text span{
    font-weight: bold;
    border-bottom: 2px solid var(--color-header-text);
}
.contact-map-container{
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
}
.contact-map-container .form-headings-text{
    color: var(--color-header-text);
    text-align: center;
}
.contact-map-container .form-headings-text span{
    font-weight: bold;
    border-bottom: 2px solid var(--color-header-text);
}

.contact-map-container iframe{
    width: 100% !important;
    height: 25rem !important;
}

.form-headings-text{
    font-size: 1rem;
}
/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
   
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
   
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
   
}
