

.slider-container{
    width: 80%;
    margin: 0 auto;
}


/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
    .slider-container div img{
        width: 200px;
    }
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .slider-container div img{
        width: 200px;
    }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    .slider-container div img{
        width: 250px;
    }
}

