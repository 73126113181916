
@import url("../variables.css");

.product-intro-container{
    display: grid;
    grid-template-rows: 4fr 2fr 2fr 2fr 3fr;
    width: 100%;
    background: url("../../assets/productpg/productintro.png");
    background-size: cover;
    color: var(--color-text-white);
    text-align: center;
}
.product-intro-container h2{
    font-weight: bold;
}
.product-intro-container p{
    color: var(--color-text-white);
}
.subproduct-container{
    display: grid;    
}
.pro-product-slide{   
    border-radius: 3px;
    transition: transform 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
.pro-product-slide:hover{
    transform: scale(1.1); 
    cursor: pointer;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.pro-product-slide h6{
    font-weight: 600;
    color: var(--color-header-text);
    margin-top: 1rem;
}
.navheadings{
    color: var(--color-header-text) !important;
}
/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
    .product-intro-container{      
        height: 30rem;
    }
    .subproduct-container{
        grid-template-columns: auto auto auto auto;  
        grid-gap: 1rem;
    }
    .pro-product-slide{  
        width: 16rem;
        padding: 0.5rem;
    }
    .pro-product-slide img{
        width: 15rem;
    }
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .product-intro-container{      
        height: 20rem;
    }
    .product-intro-container h2{
        font-size: 1rem;
    }
    .product-intro-container p{
        font-size: 0.6rem;
    }
    /* product */
    .subproduct-container{
        grid-template-columns: auto auto auto;  
        grid-gap: 2rem;
    }
    .pro-product-slide img{
        width: 10rem;
    }
    .pro-product-slide h6{
        font-size: 0.9rem;
    }
    .pro-product-slide{
        font-size: 0.8rem;
    }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    .product-intro-container{        
        height: 25rem;
    }
    .product-intro-container h2{
        font-size: 1.4rem;
    }
    .product-intro-container p{
        font-size: 0.9rem;
    }
    /* product */
    .subproduct-container{
        grid-template-columns: auto;  
        grid-gap: 1rem;
    }
    .pro-product-slide{
        padding: 2rem;       
    }
    .pro-product-slide img{
        width: 100%;
    }
}