
.carousel-container {
    width: 80%;
    margin: 0 auto;
}

.product-slide {
    text-align: center;
    padding: 10px;
    transition: transform 0.3s ease;
}
.product-slide:hover{
    transform: scale(1.1); 
    cursor: pointer;
}

.product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    max-height: 300px; /* Adjust the height as per the design */
}



.button-coursel{
    color: #3C3679;
    font-size: 18px;
    width: 30px;
    height: 30px;
    font-weight: bold;
    text-align: center;
    margin: auto;
    border: 1.5px solid #FAE0E1;
    background: #fff;
    border-radius: 3px;
    margin-left: 1rem;
}

.pd-slider-text{
    color: var(--color-header-text);
    font-size: 1rem;
 }
 .pd-slider-text h5{
    color: var(--color-header-text);
    font-size: 1rem;
 }


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
 .pd-slider-text{
    color: var(--color-header-text);
    font-size: 1rem;
 }
}