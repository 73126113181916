
@import url("../variables.css");

.about-md-container{
    display: grid;
}

.about-md-content{
    text-align: justify;
}

.about-md-heading h1{
    font-weight: bold;
}

.about-md-content p ul li{
    font-weight: 600;
}
.about-qa-container h1{
    text-align: center;
    font-weight: bold;
}

.about-infra-container h1{
    font-weight: bold;
    color: var(--color-header-text);
    text-align: center;
}

/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
    .about-md-container{
        grid-template-columns: auto auto;
        grid-gap:1rem;
    }    
    .about-md-left img{
        width: 100%;
        /* margin: auto; */
    }  
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .about-md-container{
        grid-template-columns: 7fr 5fr;
        grid-gap:1rem;
    }
    .about-md-right h1{
        font-size: 1rem;
    }
    .about-md-right .about-md-content p{
        font-size: 0.6rem;
    }
    .about-md-left{
        margin: auto;
    }
    .about-md-left img{
        width: 100%;
        /* margin: auto; */
    }       
    .about-qa-container h1{
        font-size: 1rem;
    }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {

    .about-md-heading h1{
        font-weight: 1rem;
    }       
    .about-md-container {      
        grid-template-rows: auto auto;
        grid-gap: 1rem;
    }

    /* Ensure the MD image comes first and content is below */
    .about-md-left {
        /* max-width: 200px; */
        order: 1;
        padding: 1rem;
        border-radius: 5px;       
    }
    .about-md-left .mdimg{
        width: 20rem;
    }
    .about-md-left .officeimg{
        width: 20rem;
    }

    .about-md-right {
        order: 2;
        padding: 1.5rem;
        border-radius: 5px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    }
   
}