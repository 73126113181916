@import url("../variables.css");

.footer-container{
    width: 100%;
    background: url("../../assets/homepg/banner1.png");
    background-size: cover;
    display: grid;
    color: var(--color-text-white);
}
.footer-leftcontainer{
    display: grid;
}

.footer-subcontainer-img{
    margin: auto;
}
.footer-subcontainer-img img{
    width: 100%;
}
.footer-subcontainer{
    display: grid;
}
.footer-insubcontainer{
    display: grid;
}
.footer-insubcontainer div p{
    font-weight: bold;
    color: var(--color-text-white);
}
.footer-suboutercontainer{
    display: grid;
}

.footer-sub-inoutercontainer{
    display: grid;
}

.footer-sub-inoutercontainer img{
    cursor: pointer;
}
.footer-insubcontainer .footer-links-atag{
    margin-left: -15px;
}

/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
    .footer-container{
        grid-template-rows: auto auto;
        grid-gap: 1rem;
        padding: 2rem;
    }
    .footer-leftcontainer{
        grid-template-columns: auto auto;
    }
    
    .footer-subcontainer{
        grid-template-columns: auto auto;
        grid-gap: 1rem;
    }
    .footer-insubcontainer{
        grid-template-rows: 3fr 3fr;
        grid-gap: 0.5rem;
    }
    .footer-suboutercontainer{
        grid-template-columns: 8.1fr 3.9fr;
    }
    .footer-suboutercontainer div{
        margin-top: auto;
    }
    .footer-sub-inoutercontainer{
        grid-template-columns: auto auto auto;
        width: 50%;
    }
    .footer-links-atag .nav-link span{
        font-size: 1rem;
    }
    .footer-links-atag .nav-link svg{
        font-size: 1.2 rem;
    }
}

/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .footer-container{
        grid-template-rows: auto auto;
        grid-gap: 1rem;
        padding: 1rem;
    }
    .footer-leftcontainer{
        grid-template-columns: auto auto;
    }
    
    .footer-subcontainer{
        grid-template-columns: auto auto;
        grid-gap: 1rem;
    }
    .footer-insubcontainer{
        grid-template-rows: 3fr 3fr;
        grid-gap: 1rem;
    }
    .footer-insubcontainer div p{
        font-size: 1rem;
        font-weight: bold;
    }
    .footer-insubcontainer div div{
        font-size: 0.6rem;
    }

    .footer-suboutercontainer{
        grid-template-columns: 8.1fr 3.9fr;
    }
    .footer-suboutercontainer div{
        font-size: 0.6rem;
    }
    .footer-sub-inoutercontainer{
        grid-template-columns: auto auto auto;
        width: 50%;
        grid-gap: 1rem;
    }
    .footer-links-atag .nav-link span{
        font-size: 0.6rem;
    }
    .footer-links-atag .nav-link svg{
        font-size: 1rem;
    }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    .footer-container{
        grid-template-rows: auto auto;
        grid-gap: 1rem;
        padding: 1rem;
        margin-top: 3rem;
    }
    .footer-leftcontainer{
        grid-template-rows: auto auto;
        grid-gap: 1rem;
    }    
    .footer-subcontainer{
        grid-template-columns: auto auto;
        grid-gap: 2rem;
    }
    .footer-insubcontainer{
        grid-template-rows: auto auto;
        grid-gap: 1rem;
    }
    .footer-insubcontainer div p{
        font-size: 1.4rem;
        font-weight: bold;
    }
    .footer-insubcontainer div div{
        font-size: 0.9rem;
    }

    .footer-suboutercontainer{
        grid-template-columns: 5fr 7fr;
    }
    .footer-suboutercontainer div{
        font-size: 0.9rem;
    }
    .footer-sub-inoutercontainer{
        grid-template-columns: auto auto auto;
        width: 50%;
        grid-gap: 1rem;
    }
    .footer-links-atag .nav-link span{
        font-size: 1rem;
    }
    .footer-links-atag .nav-link svg{
        font-size: 1.3rem;
    }
}