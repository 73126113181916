/* variables.css */
:root {
    --font-family-name:jost;
    --font-size-small: 14px;
    --font-size-medium: 16px;
    --font-size-large: 18px;
    
    --color-header-text: #58378E;
    --color-background: #ffffff;
    --color-text: #333;
    --color-shadow: rgba(0, 0, 0, 0.1);
    --color-bg-button : rgb(191,123,251);
    --color-text-white:#ffffff;
    --color-text-black:#000000;
    --color-text-team-heading : #2A0070;
    --color-text-team-subheading: #340089;
    --color-input-border:#B4BEC8;
    --color-vision-content:rgba(15, 0, 26, 0.6);
    --color-mission-content:#212124;
}


/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
   
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
   
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
   
}
