/* Container for the scrolling images */
.supplier-container {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

/* Styling for continuous animation */
.scrolling-images {
    display: inline-flex;
    grid-gap:2rem;
    animation: scrollRight 30s linear infinite;
}

/* Images styling */
.supplier-container img {    
    height: auto;
    margin: auto;
    object-fit: cover;
    flex-shrink: 0;
}

/* Responsive image widths */
@media (min-width: 1024px) {
    .supplier-container img {
        width: 200px;
    }
}

@media (min-width: 481px) and (max-width: 1023px) {
    .supplier-container img {
        width: 9rem;
    }
}

@media (max-width: 480px) {
    .supplier-container img {
        width: 9rem;
    }
}

/* Keyframes for scrolling animation */
@keyframes scrollRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}