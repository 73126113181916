@import url("./components/variables.css");

/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}

body {
  font-family: var(--font-family-name) !important;
}
p{
  color: var(--color-mission-content);
}

/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
   
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
  h1{
    font-size: 1.4rem !important;
  } 
  h3{
    font-size: 1.3rem !important;
  } 
  p{
    font-size: 0.8rem !important;
   }
   div{
    font-size: 0.8rem !important;
   }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
   
}
