@import url("../variables.css");

.error-message {
    color: red;
    font-size: 0.875em;
    margin-top: 5px;
  }
  
.required-asterisk {
color: red;
}
.forms-containers{
    display: grid;
}

.forms-sub-container{
    display: grid;
}
.forms-btn-container{
    margin: auto;
}

.forms-sub-container label{
    font-weight: bold;
}

.forms-sub-container .forms-input{
    width: 100%;
    outline: none;
    border: 1px solid var(--color-input-border) !important;
    border-radius: 3px;
    color: var(--color-mission-content);
    text-indent: 10px;
}
.forms-sub-container .forms-textarea{
    text-indent: 10px;
    outline: none;
    border: 1px solid var(--color-input-border) !important;
}
.form-productgrid{
    display: grid;
    grid-template-columns: auto 3fr;
    grid-gap: 1rem;
}
.form-sucess-subcont{
    text-align: center;
    color: var(--color-header-text);
}
.form-sucess-subcont h1{
    font-weight: bold;
}
/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
    .forms-containers{
        grid-gap: 1rem;
    }
    .forms-sub-container{
        grid-template-columns: 6fr 6fr;
        grid-gap:1rem;
    }
    .forms-sub-container label{
        font-size: 1rem;
    }
    .forms-sub-container .forms-input{
        height: 50px;        
    }
    .forms-sub-container .forms-textarea{
        height: 6rem;
        width: 100%
    }
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .forms-containers{
        grid-gap: 1rem;
    }
    .forms-sub-container{
        grid-template-columns: 6fr 6fr;
        grid-gap:1rem;
    }
    .forms-sub-container label{
        font-size: 1rem;
    }
    .forms-sub-container .forms-input{
        height: 50px;        
    }
    .forms-sub-container .forms-textarea{
        height: 6rem;
        width: 100%
    }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    .forms-containers{
        grid-gap: 1rem;
    }
    .forms-sub-container{
        grid-template-columns: 6fr 6fr;
        grid-gap:1rem;
    }
    .forms-sub-container label{
        font-size: 1rem;
    }
    .forms-sub-container .forms-input{
        height: 50px;        
    }
    .forms-sub-container .forms-textarea{
        height: 6rem;
        width: 100%
    }
}