@import '../variables.css'; /* Import the common CSS file */

/* background-color: var(--color-background); */
/* Intro container */
.home-introimg-container{
    border-radius: 30px;
    width: 100%;
    display: grid;   
    background: url("../../assets/homepg/introlapbg.png");
    background-size: cover;
}

.home-introimg-left{
    display: grid;
    grid-template-rows: 2fr auto auto auto;
    padding: 5rem;
    color: var(--color-text-white);
}

.home-introimg-left p{
    color: var(--color-text-white)
}

.home-introimg-right img{
    width: 100%; 
}

/* icon container */
.home-icon-container{
    display: grid;
    padding: 3rem;
}
.home-icon-subcontainer{
    display: grid;
    grid-template-rows: 3fr 2fr 2fr;
    grid-gap: 1rem;
    padding: 1rem;
}
.home-icon-subcontainer div{
    margin: auto;
}
.home-icon-subcontainer div img{
    width: 5rem;
}

/* for the about us container */
.home-aboutus-container{
    border-radius: 30px;
    width: 100%;
    display: grid;    
    grid-gap: 1rem;
}
.home-about-right{
    color: var(--color-text-black);
    margin: auto;
}
.home-about-right h1{
    font-weight: bold;
}
.home-about-right .home-welcome-para{
    text-align: justify;
} 
.aboutus-btn-container{
    display: none;
}

/* for mission container */
.home-about-right div p div{
    text-align: justify;
}
.aboutus-btn-container{
    display: none;
}
/* for vision */
.home-vision-container{
    display: grid;
}
/* for banner1-container */
.home-banner1-container{
    width: 100%;
    display: grid;
    background: url("../../assets/homepg/banner1.png");
    background-size: cover;
    color: var(--color-text-white);
}
/* for team container */
.home-team-container{
    width: 100%;
    display: grid;
    background: url("../../assets/homepg/team.png");
    background-size: cover;        
}
.home-team-container h1{
    text-align: center;
    font-weight: bold;
    color: var(--color-text-team-heading);
}
.home-team-container p{
    text-align: center;
    color: var(--color-text-team-subheading);
}
.home-team-container div{
    margin: auto;
}
/* home-carosel-container */

.home-carosel-container .navheadings {/* Inactive Tab Heading */
    
    color: var(--color-text-black);
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 10px; /* Add spacing between tabs */
}


.home-carosel-container .navheadings.active { /* Active Tab Heading */
    /* Background color for active tab */
    color: var(--color-text-white);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-weight: bold;
}


.home-carosel-container .navheadings:hover { /* Hover Effect for Inactive Tab */
    background-color: #e6e6e6;
    cursor: pointer;
}

.home-supplier h1{
    font-weight: bold;
    text-align: center;
}

/* Responsive css starts */
/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
    .home-introimg-right img{
        width: 95%;
    }
    .home-introimg-container{      
        grid-template-columns: 6fr 6fr;        
    }
    .home-icon-container{
        grid-template-columns: auto auto auto;
    }
    .home-introimg-left h1{
        font-weight: bold;
    }
    .home-icon-container h3{
        font-weight: bold;
    }
    .home-icon-subcontainer div{
        text-align: justify;
    } 
    /* for about us container */
    .home-aboutus-container{      
        grid-template-columns: 7fr 5fr;        
    }
    /* for vision container */
    .home-vision-container{
        grid-template-columns: 5fr 7fr !important; 
    }

    /* for banner1 container */
    .home-banner1-container{
        grid-template-columns: 10fr auto;
        padding: 3rem 7rem;
        margin-top: 2rem;
    }    
    /* for team container */
    .home-team-container{
        padding: 8rem;
    }
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    /* intro container */
    .home-introimg-container{      
        grid-template-columns: 6fr 6fr;        
    }

    .home-introimg-left{        
        padding: 3rem;
        font-size: 1rem;
    }
    .home-introimg-left h1{
        font-size: 1rem;
    }
    .home-supplier h1{
        font-size: 1rem;
    }
    .home-introimg-left div p{
        font-size: 0.6rem;
    }
    
    .home-introimg-right img{
        width: 95%;       
    } 

    /* icon container */
    .home-icon-container{
        grid-template-columns: 2fr 2fr 2fr;
        padding: 1rem;
    }  
   
    .home-icon-subcontainer{
        display: grid;
        grid-template-rows: 1fr 0.2fr 0.5fr;
        grid-gap: 0.5rem;
        padding: 1rem;
    }
    .home-icon-subcontainer div{
        margin: auto;
    }
    .home-icon-subcontainer div img{
        width: 4rem;
    } 
    .home-icon-subcontainer div h3{
        font-size: 0.6rem;
        font-weight: bold;
    } 
    .home-icon-subcontainer div{
        font-size: 0.6rem;
    } 

   /* for about us container */
   .home-aboutus-container{      
    grid-template-columns: 7fr 5fr;        
    }    
    /* for vision container */
    .home-vision-container{
        grid-template-columns: 5fr 7fr !important; 
    }
    .home-about-right h1{
        font-weight: bold;
        font-size: 1rem;
    }
    .home-about-right div p{
        font-size: 0.6rem;
    }    
    /* for banner1 container */
    .home-banner1-container{
        grid-template-columns: 10fr auto;
        padding: 3rem;
        margin-top: 2rem;
    }  
    .home-banner1-container h1{
        font-size: 1rem;
    }
    /* for team container */
    .home-team-container{
        padding: 3rem;
    }
    .home-team-container h1{
        font-size: 1rem;
    }
    .home-team-container p{
        font-size: 0.6rem;
    }   
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    /* for intro container */
    .home-introimg-container{        
        grid-template-rows: auto auto !important;  
        position: relative;      
    }
    
    .home-introimg-left{   
        padding: 2rem;
        position: absolute;
        bottom: 0px;
        opacity: 1;
        z-index: 1;
    }
    
    .home-introimg-right img{
        width: 100%;
        opacity: 0.3;
    }
    .home-introimg-left h1{
        font-size: 1.4rem;
        
    }

    .home-supplier h1{
        font-size: 1.4rem;
    }
    .home-introimg-left div p{
        font-size: 0.9rem;
    }

    /* for icon container */
    .home-icon-container{
        margin-top: 1rem;
        grid-template-rows: auto auto auto;    
        grid-gap: 1rem;   
        padding: 0px; 
    }
    .home-icon-subcontainer{
        grid-template-rows: 1fr 0.5fr 1fr;
        grid-gap: 0px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        border-radius: 3px;
    }
    .home-icon-subcontainer div img{
        width: 5rem;
    }

    .home-icon-subcontainer div h3{
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
    } 
    .home-icon-subcontainer div{
        font-size: 1rem;
        text-align: justify;
    } 

    /* for about us container */
    .home-aboutus-container{        
        grid-template-rows: auto auto !important;  
        position: relative;      
        margin-top: 2rem;        
    }
    .home-about-right{
        position: absolute;      
        top: 0;
        padding: 10px;
    }
    .home-about-right h1{
        font-size: 1.4rem; 
        text-align: center;       
    }
    .home-about-right div p{
        font-size: 0.9rem;
        text-align: justify;
    }
    .home-about-right .aboutus-btn-container{
        display: block !important;
        text-align: center;
    }

    /* for misssion */
    
    .home-mission-content p{
        display: grid;
        /* grid-template-rows: 2fr 2fr 2fr;     */
        grid-gap: 0.5rem;
    }
    .home-mission-content p div span{
        display: none;
    }
    .home-mission-content p div{
        margin-left: 25%;
        text-align: left !important;
    }
    .mission-right{
        display: grid;
        grid-template-rows: auto auto auto;
        grid-gap: 1rem;
        width: 100%;
        z-index: 1;
    }
    /* for banner1 container */
    .home-banner1-container{
        margin-top: 1rem;
        grid-template-rows: auto auto;
        padding: 2rem;
    }  
    .home-banner1-container h1{
        font-size: 1.4rem;
    }
    /* for team container */
    .home-team-container{
        padding: 5rem;
        height: 15rem;
    }
    .home-team-container h1{
        font-size: 1.4rem;
    }
    .home-team-container p{
        font-size: 0.9rem;
    }   
    .home-carosel-container .navheadings{
        font-size: 0.9rem;
        border: 1px solid var(--color-bg-button) !important;
        margin-bottom: 10px !important;
    }
    .home-mob-ul{
        display: grid !important;
        grid-template-columns: auto auto;
    }
    .home-carosel-container .navheadings.active{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        font-weight: bold;
    }
}