@import url("../../variables.css");



/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
    .qa-container{
        position: relative;
        height: 90vh;
        width: 100%;
        display:flex;
        flex: 1;
        justify-content: center;   
    }
    .qa-card-container{
        height: 20%;
        width: 24rem;         
        background: #fff;       
        border-radius: 10px;
        box-sizing: "border-box"
    }
    .qa-card-img{
        width: 100%;        
    }
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .qa-container{
        position: relative;
        height: 45vh;
        width: 100%;
        display:flex;
        flex: 1;
        justify-content: center;   
    }
    .qa-card-container{
        height: 20%;
        width: 12rem;         
        background: #fff;       
        border-radius: 10px;
        box-sizing: "border-box"
    }
    .qa-card-img{
        width: 100%;        
    }
}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    .qa-container{
        position: relative;
        height: 50vh;
        width: 100%;
        display:flex;
        flex: 1;
        justify-content: center;   
    }
    .qa-card-container{
        height: 20%;
        width: 15rem;         
        background: #fff;       
        border-radius: 10px;
        box-sizing: "border-box"
    }
    .qa-card-img{
        width: 100%;        
    }
}