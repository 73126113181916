
@import '../variables.css'; /* Import the common CSS file */


/* background-color: var(--color-background); */



.navbar {
    padding: 10px 20px;
    background-color: var(--color-background) !important;
    color: var(--color-header-text) !important; 
}
.navbar a{
    color: var(--color-header-text) !important; 
}
.home-logo{
    width: 11rem;
}

.nav-link {
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 500;
}

.closebtn{
    width: 3.5rem !important;
    font-size: 1.3rem !important;
    border: 1px solid black !important;
}

.menuitems ul{
    display: flex;
    flex-direction: row;
}

.toggle-btn{
    display: none;
}

.activepage{
    font-weight: bold !important;
    /* text-decoration: underline !important; */
}
.nav-link span{
    margin-left: 5px;
    font-size: 1.3rem;
}




/* Laptop and  larger screens styling (769px to 1024px beyond) */
@media (min-width: 1024px) {
   
}


/* Tablet styling (481px to 768px) */
@media (min-width: 481px) and (max-width: 1023px) {
    .home-logo{
        width: 9rem;
    }
    .navcontainers{
        display: grid !important;
        grid-template-columns: auto auto !important;
    }

}

/* Mobile styling (up to 480px) */
@media (max-width: 480px) {
    .menuitems{
        display: none;
    }
    .toggle-btn{
        display: inline;
    }
    .home-logo{
        width: 10rem;
    }
}
